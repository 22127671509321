.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.lucky-wheel {
  height: 100vh;
  /*大转盘*/
  /*剩余抽奖次数*/
  /*弹窗*/
  /*中奖弹窗*/
  /*游戏规则弹窗*/
}
.lucky-wheel .empty-box {
  background: #fff;
  height: 100vh;
  width: 100vw;
  font-size: 0.28rem;
  color: #999;
}
.lucky-wheel .empty-box img {
  width: 3.64rem;
  height: 2.58rem;
  margin-top: 1.76rem;
  margin-bottom: 0.74rem;
}
.lucky-wheel .title {
  width: 3.98rem;
  height: 2.2rem;
  margin-top: 3.3%;
  background-image: url('../../../assets/wheel/bg_font@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .lucky-wheel .title {
    background-image: url('../../../assets/wheel/bg_font@3x.png');
  }
}
.lucky-wheel .wheel-box {
  padding-bottom: 0.22rem;
  margin: 0.48rem 0 0;
  transition: transform 3s ease;
  position: absolute;
  top: 22vh;
  background: url("../../../assets/wheel/shadow_circle@3x.png") no-repeat bottom center;
  background-size: 3.2rem 0.6rem;
}
.lucky-wheel .lucky-bottom {
  width: 100%;
  margin-top: 0.58rem;
  position: absolute;
  bottom: 4%;
}
.lucky-wheel .lucky-bottom .lucky-btn {
  width: 2.6rem;
  height: 0.96rem;
  line-height: 0.96rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.32rem;
  color: #9A6100;
  background: #ffe700;
  border-radius: 0.16rem;
  box-shadow: 0.12rem 0.14rem 0.04rem #B55D00;
}
.lucky-wheel .lucky-bottom .look {
  position: absolute;
  top: 0;
  right: 0.24rem;
  line-height: 0.96rem;
  font-size: 0.28rem;
  color: #fff;
}
.lucky-wheel .lucky-bottom .look .triangle {
  margin-left: 0.14rem;
  position: relative;
  width: 0;
  height: 0;
  border-top: 0.08rem solid transparent;
  border-bottom: 0.08rem solid transparent;
  border-left: 0.08rem solid #fff;
}
.lucky-wheel .times {
  font-size: 0.28rem;
  color: #fff;
  position: absolute;
  top: -0.8rem;
}
.lucky-wheel .sea-times {
  top: -0.35rem;
}
.lucky-wheel .f28 {
  font-size: 0.28rem;
  color: #fff;
}
.lucky-wheel .f36 {
  font-size: 0.36rem;
  color: #fff;
}
.lucky-wheel .f500 {
  font-weight: 500;
}
.lucky-wheel .toast-mask {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  width: 100%;
  height: 100%;
}
.lucky-wheel .toast {
  width: 4.7rem;
  height: 6.58rem;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  border-radius: 0.2rem;
  background-image: url('../../../assets/wheel/img_farm_bg@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .lucky-wheel .toast {
    background-image: url('../../../assets/wheel/img_farm_bg@3x.png');
  }
}
.lucky-wheel .toast .toast-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 0.88rem;
  padding-bottom: 0.26rem;
}
.lucky-wheel .toast .toast-container .toast-circle {
  width: 3.44rem;
  height: 3.44rem;
  border-radius: 50%;
  border: 0.08rem solid #FF6600;
}
.lucky-wheel .toast .toast-container .toast-circle img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.lucky-wheel .toast .toast-container .toast-bottom {
  font-size: 0.28rem;
  color: #FF6600;
  line-height: 0.4rem;
  text-align: center;
  width: 100%;
}
.lucky-wheel .toast .toast-container .toast-bottom .toast-btn {
  width: 2.2rem;
  height: 0.68rem;
  line-height: 0.68rem;
  text-align: center;
  font-size: 0.28rem;
  color: #fff;
  background: #fe552e;
  border-radius: 0.34rem;
  margin-top: 0.16rem;
}
.lucky-wheel .alert-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  backface-visibility: hidden;
}
.lucky-wheel .alert-container .alert-content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.lucky-wheel .rules-alert .alert-content {
  top: 0.7rem;
}
.lucky-wheel .rules-alert .alert-content .rules-container {
  width: 6.7rem;
  height: 8.86rem;
  margin-bottom: 0.4rem;
  background-color: #fff;
  border-radius: 0.08rem;
  border: 0.04rem solid #FE552E;
  overflow: hidden;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen {
  width: 6.5rem;
  height: 8.66rem;
  padding: 0.3rem 0.2rem;
  background-color: #fff;
  border-radius: 0.08rem;
  border: 0.04rem solid #FE552E;
  overflow: auto;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .active-time {
  width: 100%;
  font-size: 0.26rem;
  color: #666;
  line-height: 0.44rem;
  margin-bottom: 0.3rem;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .active-explain {
  margin: 0;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .top-title {
  display: flex;
  align-items: center;
  margin-bottom: 0.76rem;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .top-title .dot {
  display: inline-block;
  width: 0.08rem;
  height: 0.08rem;
  background-color: #999;
  border-radius: 50%;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .top-title .txt {
  margin: 0 0.2rem;
  font-size: 0.28rem;
  color: #999;
  line-height: 0.44rem;
  letter-spacing: -0.02rem;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .rules-title {
  margin-bottom: 0.6rem;
  font-size: 0.36rem;
  color: #FE552E;
  line-height: 0.5rem;
  letter-spacing: 0.01rem;
  font-weight: bold;
}
.lucky-wheel .rules-alert .alert-content .rules-container .rules-screen .rules-box .rules-content {
  width: 100%;
  max-height: 60%;
  font-size: 0.26rem;
  color: #666;
  line-height: 0.44rem;
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.lucky-wheel .rules-alert .alert-content .close-btn {
  width: 0.68rem;
  height: 0.68rem;
  margin: 0 auto;
  background-image: url('../../../assets/icon_denglu_guanbi@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  .lucky-wheel .rules-alert .alert-content .close-btn {
    background-image: url('../../../assets/icon_denglu_guanbi@3x.png');
  }
}
.lucky-wheel .zoom-enter-active {
  animation-name: zoom-in;
  animation-duration: 1s;
}
.lucky-wheel .zoom-leave-active {
  animation-name: zoom-out;
  animation-duration: 1s;
}
@keyframes zoom-in {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 1;
  }
}
@keyframes zoom-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  100% {
    opacity: 0;
  }
}

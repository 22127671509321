.border-none[data-v-3f71d704]:before {
  display: none;
}
.border-none[data-v-3f71d704]:after {
  display: none;
}
.flex-fix[data-v-3f71d704] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-3f71d704]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-3f71d704] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-3f71d704] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-3f71d704] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-3f71d704] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-3f71d704] {
  *zoom: 1;
}
.clear-fix[data-v-3f71d704]:before,
.clear-fix[data-v-3f71d704]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#_big-wheel[data-v-3f71d704] {
  position: relative;
  width: 5.84rem;
  height: 5.84rem;
  padding: 0.3rem;
  background-image: url('../../assets/wheel/bg_circle@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
#_big-wheel[data-v-3f71d704] {
    background-image: url('../../assets/wheel/bg_circle@3x.png');
}
}
#_big-wheel ._big-wheel-canvas[data-v-3f71d704] {
  width: 100%;
  -webkit-transition: -webkit-transform cubic-bezier(0.11, 0.77, 0.2, 0.94);
  transition: -webkit-transform cubic-bezier(0.11, 0.77, 0.2, 0.94);
  transition: transform cubic-bezier(0.11, 0.77, 0.2, 0.94);
  transition: transform cubic-bezier(0.11, 0.77, 0.2, 0.94), -webkit-transform cubic-bezier(0.11, 0.77, 0.2, 0.94);
}
#_big-wheel ._big-wheel-go-img[data-v-3f71d704] {
  width: 2.2rem;
  height: 3.26rem;
  position: absolute;
  top: 13%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
#_big-wheel {
  position: relative;
  width: 5.84rem;
  height: 5.84rem;
  padding: 0.3rem;
  background-image: url('../../assets/wheel/bg_circle@2x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
  #_big-wheel {
    background-image: url('../../assets/wheel/bg_circle@3x.png');
  }
}
#_big-wheel ._big-wheel-canvas {
  width: 100%;
  transition: transform cubic-bezier(0.11, 0.77, 0.2, 0.94);
}
#_big-wheel ._big-wheel-go-img {
  width: 2.2rem;
  height: 3.26rem;
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
}
